<template>
    <div :style="desktopStyle" class=" relative">
      <div class="top relative w-full h-full">
        <!-- Title Animation -->
        <Vue3Lottie 
          class=" absolute top-0 w-full"
          :animationData="titleJSON"
        />
        <!-- Image Frame -->
        <div class="py-[30vh] w-3/4 mx-auto">
          <img 
            class="mx-auto"
            :src="ruleSrc"
          />
        </div>
        <!-- Marquee -->
        <marquee-text class="text-white absolute w-[100vh] top-0 bottom-0 rotate-90 right-3 tracking-widest font-goldman" :duration="20" :repeat="3">
        Halfway to Retirement 7/31-8/4 Started From the Bottom Halfway Studio. 
        </marquee-text>
      </div>
      <!-- Footer -->
      <div class="footer w-full bg-black h-[10vh] absolute bottom-0 grid grid-cols-12 px-4">
        <div class="col-span-6 flex items-center pl-4">
          <img
            class="w-5/6 mx-auto"
            :src="govNoticeSrc"
          />
        </div>
        <img
          class="col-span-3 cursor-pointer"
          @click="goToRank"
          :src="rankBtnSrc"
        />
        <img
          class="col-span-3 cursor-pointer xl:block hidden"
          @click="goToBuy"
          :src="buyBtnSrc"
        />
        <img
          class="col-span-3 cursor-pointer xl:hidden block"
          @click="tabletAlert"
          :src="buyBtnSrc"
        />
      </div>
    </div>
</template>

<script>
import desktopBackground from '@/assets/desktopBackground.webp';
import imageFrame from '@/assets/picture_frame.png';
import { Vue3Lottie } from 'vue3-lottie';
import titleJSON from '@/assets/title.json';
import MarqueeText from 'vue-marquee-text-component';
import { detect } from 'detect-browser';
import liff from '@line/liff';
import { detectIncognito } from 'detect-incognito';

export default {
  name: "DesktopView",
  components: {
    Vue3Lottie,
    MarqueeText,
  },
  methods: {
    goToRank() {
      window.open("https://user.hwstud.io/ranking", "_blank");
    },
    goToBuy() {
      // detect instagram browser
      let browser = detect();
      if (browser.name === 'instagram') {
        alert("請使用外部瀏覽器，以獲得更好的體驗！");
        return;
      }

      // detect browser in private mode
      if (this.incognito) {
        alert("請關閉無痕模式，以獲得更好的體驗！");
        return;
      }

      // login with liff
      if (this.liffReady) {
        liff.login({
          redirectUri: 'https://event.hwstud.io/line-friend'
        });
      }
    },
    tabletAlert() {
      alert('請使用手機操作，或將裝置垂直以獲得更好的購票體驗！')
    },
  },
  data(){
    return {
      slideChangeInterval: null,
      titleJSON,
      desktopStyle: {
        'background-image': `url(${desktopBackground})`,
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
      },
      imageFrameStyle: {
        'background-image': `url(${imageFrame})`,
        'background-repeat': 'no-repeat',
        'background-position': 'center',
      },
      govNoticeSrc: require("@/assets/gov_notice.png"),
      rankBtnSrc: require("@/assets/rank_btn.png"),
      buyBtnSrc: require("@/assets/buy_btn.png"),
      slideImage: "",
      slides: [
        "96d4c78831fbf06a2867127eee475254.jpeg",
        "b1ecbc6d6779e7a3335f67e67a411436.jpeg",
        "f972121dd4852414500da3b8f91973dd.jpeg",
        "70117700b5aea7d969f9815696b339f6.jpeg",
        "ca69bc27142c55a913104160e27a5ec6.jpeg",
        "e2276cb33eca2797af1b947eea433951.jpeg",
        "b296267ea0b43e00c324a1d9b3bd7943.jpeg",
        "7c5e796a2f519a52658bfe975b958320.jpeg",
      ],
      ruleSrc: require("@/assets/RULE.png"),
      incognito: false,
    }
  },
  mounted(){
    this.slideImage = this.slides[0];
    this.slideChangeInterval = setInterval(() => {
      if (this.slideImage === this.slides[this.slides.length - 1]) {
        this.slideImage = this.slides[0];
      }else{
        this.slideImage = this.slides[this.slides.indexOf(this.slideImage) + 1];
      }
    }, 6000);
    // detect incognito
    detectIncognito().then(result => {
      this.incognito = result.isPrivate;
    })
    liff.init({
      liffId: "1656804955-p0NYz5o8",
    })
      .then(() => {
        this.liffReady = true;
      })
  }
}
</script>