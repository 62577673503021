import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/line-friend',
    name: 'line-friend',
    component: () => import('../views/lineFriend.vue'),
    alias: '/line-friend&liffIsEscapedFromApp=true'
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/checkout.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('../views/result.vue')
  },
  {
    path: "/affiliate/:clerk",
    name: "Affiliate",
    component: () => import('../views/HomeView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
