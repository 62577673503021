<template>
    <div class="w-screen h-screen overflow-hidden flex items-center" :style="desktopStyle">
      <Vue3Lottie
        class="md:w-1/3 w-2/3 mx-auto"
        :animationLink="`https://assets9.lottiefiles.com/packages/lf20_cdlzt6kt.json`"
      />
    </div>
</template>


<script>
import desktopBackground from '@/assets/desktopBackground.webp';
import { Vue3Lottie } from 'vue3-lottie'

export default {
  name: "LoadingPage",
  components: {
    Vue3Lottie,
  },
  data(){
    return {
      desktopStyle: {
        'background-image': `url(${desktopBackground})`,
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
      },
    }
  }
}
</script>