import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import store from './store'
import { createGtm } from '@gtm-support/vue-gtm'


const app = createApp(App);

app.use(store);
app.use(router);
app.use(createGtm({
    id: "GTM-TTX9R6X",
    defer: false,
    trackOnNextTick: false,
    vueRouter: router,
}))

app.mount('#app')