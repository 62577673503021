<template>
  <div>
    <transition name="fade">
      <Loading v-if="isLoading" class=" z-50 absolute" />
    </transition>
    <div class="w-screen h-screen grid grid-cols-10">
      <desktop 
        class="onlyPC h-screen xl:col-span-7 lg:col-span-10 hidden lg:inline-block"
      />
      <router-view class="mobileScreen h-screen xl:col-span-3 lg:hidden col-span-10 xl:inline-block" />
    </div>
  </div>
</template>

<script>
import Loading from "./views/Loading.vue"
import desktop from '@/components/home/desktop.vue';

export default {
  name: "MainPage",
  components: {
    Loading,
    desktop,
  },
  data(){
    return {
      isLoading: true,
      startTime: 0,
    }
  },
  mounted(){
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        // Record Clerk
        if (this.$route.params.clerk !== undefined) {
          let expired = new Date();
          window.localStorage.setItem("clerkExp", (expired.getTime() + (10 * 60 * 1000)).toString());
          window.localStorage.setItem("clerk", (this.$route.params.clerk).toString())
        }
        // Check Expired
        if (window.localStorage.getItem("clerk") && window.localStorage.getItem("clerkExp")) {
          let now = new Date();
          if (now.getTime() > window.localStorage.getItem("clerkExp")) {
            window.localStorage.removeItem("clerk");
            window.localStorage.removeItem("clerkExp");
          }
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
      }
    }
  },
}
</script>

<style>
body {
  overflow: hidden;
}
#app {
  background: #333333;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>